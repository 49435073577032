import * as React from 'react';
import '@fontsource/comfortaa/300.css'; // Weight 300.
import '@fontsource/comfortaa'; // Defaults to weight 400.
import '@fontsource/comfortaa/500.css'; // Weight 500.
import '@fontsource/comfortaa/600.css'; // Weight 600.
import '@fontsource/comfortaa/700.css'; // Weight 700.
import Layout from '../components/layout';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import * as styles from '../styles/contact.module.css';
import { useForm, ValidationError } from '@formspree/react';
import { navigate } from 'gatsby';
import { Grid } from '@material-ui/core';

const ContactStyled = styled.div`
	font-size: 20px;
`;
// styles
const pageStyles = {
	color: '#232129',
	webkitFontSmoothing: 'antialiased',
	fontFamily: 'Comfortaa',
	marginTop: '3em',
};
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
};
// markup

export default function EnterprisePage() {
	const [state, handleSubmit] = useForm('mjvjavgj');
	if (state.succeeded) {
		navigate('/thanks');
		// return <p>Thank you for your submission!</p>
	}
	const form = (subject) => {
		return (
			<form onSubmit={handleSubmit}>
				<input type='hidden' name='_subject' value={subject} />
				<div className='field'>
					<label className={styles.formLbl}>
						Name:
						<input type='text' name='Name' className={styles.formField} />
					</label>
				</div>
				<div className='field'>
					<label className={styles.formLbl}>
						Email:
						<input type='email' name='Email' className={styles.formField} />
						<ValidationError
							prefix='Email'
							field='email'
							errors={state.errors}
						/>
					</label>
				</div>

				<div className='field'>
					<label className={styles.formLbl} htmlFor='Message'>
						Message
					</label>
					<textarea className={styles.formField} name='Message' rows='6' />
				</div>

				<ul
					style={{
						display: 'flex',
						justifyContent: 'center',
						padding: '0',
					}}
				>
					<li className={styles.formBtnLi}>
						<button
							type='submit'
							className={styles.formBtn}
							disabled={state.submitting}
						>
							{state.submitting ? 'Submitting...' : 'Send Message'}
						</button>
					</li>
					<li className={styles.formBtnLi}>
						<input className={styles.formBtn} type='reset' value='Clear' />
					</li>
				</ul>
			</form>
		);
	};
	return (
		<ContactStyled>
			<Layout>
				<Container>
					<div style={{ margin: '6em 2em' }}>
						<center>
							<Grid container spacing={5}>
								<Grid item xs={12} sm={6}>
									<h2>Enterprise</h2>
									<div className=''>
										Grow My Life can be tailored for your enterprise. Contact us
										to learn more.
									</div>
								</Grid>
								<Grid item xs={12} sm={6}>
									<iframe
										style={{ width: '-webkit-fill-available' }}
										height='315'
										src='https://www.youtube.com/embed/ucB4AYnuohg'
										title='YouTube video player'
										frameborder='0'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowfullscreen
									></iframe>
								</Grid>
							</Grid>
						</center>
						<Container maxWidth='md'>
							{form('Business Enquiry on Grow My Life')}
						</Container>
					</div>
				</Container>
			</Layout>
		</ContactStyled>
	);
}
